import React from 'react';
import { Router, Route, Switch, Link } from 'react-router-dom';

import ProjectList from './ProjectList';
import AboutMe from './AboutMe';
import DrDiary from './DrDiary';
import AwoolPlanet from './AwoolPlanet';
import Mmume from './Mmume';
import Syds from './Syds';
import ArielNetworks from './ArielNetworks';
import TaxiJoin from './TaxiJoin';
import history from "./History";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initialState;

    this.fn_setAboutContent = this.fn_setAboutContent.bind(this);
    this.fn_setMenu = this.fn_setMenu.bind(this);
  }

  get initialState() {
    return {
      menu: 'open'
      ,aboutContent: 'ME'
    };
  }

  fn_setAboutContent = (aboutContent) => {
    this.setState({ aboutContent: aboutContent });
  }

  fn_setMenu = (menu) => {
    this.setState({ menu: menu});
  }

  componentDidMount() {
  }

  render() {
    return (
      <Router history={history}>
        <div>
          <div className="vertical-line">&nbsp;</div>
          <div className="container-fluid">
            <div className="row home">
              <div className="col-12 col-sm-6">
                <div className="row home_title">
                  <div className="col-12">
                    <span className={"home__work " + (this.state.aboutContent !== "ME" ? "u-border-bottom " : "")} onClick={(event) => this.fn_setMenu("open")}>WORK<span className={"" + this.state.menu === "open" ? "u-opacity-1" : "u-opacity-0-2-xs"}> LIST</span></span>
                  </div>
                  <div className="col-12">
                    <div>
                      <ProjectList
                        fn_setAboutContent = {this.fn_setAboutContent}
                        aboutContent = {this.state.aboutContent}
                        fn_setMenu = {this.fn_setMenu}
                        menu = {this.state.menu} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 vertical-line-2">
                <div className="row home_title">
                  <div className="col-6 home__about">
                    <Link to="/about-me" onClick={(event) => this.fn_setAboutContent("ME")}><span className={"home-about " + (this.state.aboutContent === "ME" ? "u-border-bottom" : "")}>ABOUT <span className={"" + this.state.aboutContent === "ME" ? "u-opacity-1" : "u-opacity-0-2"}>ME</span></span></Link>
                  </div>
                  <div className="col-6 home__resume">
                    <a href="/resume_kyungmin.pdf" target="_blank">RESUME</a>
                  </div>
                </div>
                <div className="row home_title">
                  <div className="col-12 home__about home__content">
                    <Switch>
                      <Route exact path="/" component={AboutMe} />
                      <Route path="/about-me" component={AboutMe} />
                      <Route path="/projects/dr-diary" component={DrDiary} />
                      <Route path="/projects/awool-planet" component={AwoolPlanet} />
                      <Route path="/projects/mmume" component={Mmume} />
                      <Route path="/projects/syds" component={Syds} />
                      <Route path="/projects/ariel-networks" component={ArielNetworks} />
                      <Route path="/projects/taxi-join" component={TaxiJoin} />
                    </Switch>
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}

export default Home;
