import React from 'react';
import Swiper from 'react-id-swiper';

class TaxiJoin extends React.Component {
  render() {
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      spaceBetween: 10,
      slidesPerView: 4.2,
      freeMode: true,
    }

    return (
    <div className="taxi">
			<div className="taxi__header">
        {/* <img className="" src={require('./../images/taxijoin_etc01.png')} alt={'Taxi Join Project'} /> */}
        <p className="content-title"><span>a</span>bstract</p>
        <p className="content-article">
          'Taxi Join' service is a sharing economy model. 
          When users take a taxi, they can share empty seats with someone 
          going in the same direction of destination. As a result, 
          users save taxi cost to at least half and it also helps the environment.
        </p>
        <p className="content-article-2nd">
          The application gives users a great experience to manage getting to their 
          destination efficiently. There are several virtual ‘Taxi Join’ stations 
          helping users find their companies easily.
        </p>
        <div>
          <p className="content-title"><span>p</span>roject</p>
          <div className="content-article">
            <Swiper className="swiper" {...params}>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/taxijoin_01.png')} alt={'Taxi Join Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/taxijoin_02.png')} alt={'Taxi Join Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/taxijoin_03.png')} alt={'Taxi Join Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/taxijoin_04.png')} alt={'Taxi Join Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/taxijoin_05.png')} alt={'Taxi Join Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/taxijoin_06.png')} alt={'Taxi Join Project'} /></div>
              </div>
            </Swiper>
          </div>
          <p className="content-article">
            Users choose their starting point, destination, time, and payment method 
            on their ‘Taxi Join’ application. Additionally, users can filter gender 
            of passengers especially for women to feel safe from sharing taxi with 
            strangers and sharing taxi cost.
          </p>
          <p className="content-title"><span>a</span>chivement</p>
          <div className="content-article content-final">
            <p>• Creative Challenge Software R&D Project By MSIP(Ministry of Science, ICT and Future Planning)</p>
          </div>
        </div>        
      </div>
    </div>
    );
  }
}

export default TaxiJoin;
