import React from 'react';

class ArielNetworks extends React.Component {
  render() {
    return (
    <div className="content">
			<p className="content-title"><span>a</span>bstract</p>
      <p className="content-article">
        Ariel Networks is a small company made of 40 people that helps giant 
        service provider companies, such as SK telecom, LG U+, KT. I joined the 
        team as an intern for three months. I worked on a project developing LTE 
        network services. The service provides LTE communication speed to purchased companies’ 
        own workers based on their location. I developed a monitoring system, 
        which first oversees the amount of traffic and abnormal conditions and then, 
        controls and manages the situation.
      </p>
      <p className="content-title"><span>p</span>roject</p>
      <p className="content-article">
        • LTE company private network service.
      </p>
      <p className="content-title"><span>e</span>xperience</p>
      <div className="content-article content-final">
        <p>• Development based C, C++ & Linux</p>
        <p>• Memory database</p>
      </div>
    </div>
    );
  }
}

export default ArielNetworks;
