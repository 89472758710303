// Import the React and ReactDOM libraries
import React from 'react';
import ReactDOM from 'react-dom';

import Home from './components/Home';

// scss import
require('./sass/main.scss');

// Create a react component
const App = () => {
  return (
    <Home />
  );
};

// Take the react component and show it on the screen
ReactDOM.render( <App />, document.querySelector('#root') );
