import React from 'react';

class Syds extends React.Component {
  render() {
    return (
      <div className="">
        <div className="row content">
          <p className="content-title"><span>a</span>bstract</p>
          <p className="content-article">
            Samyang is one of the top 200th companies in South Korea. 
            It has three main industries - chemical, food, and research. 
            I worked as a programmer in the System Management Department, 
            especially on Manufacturing Execution Systems, associated with food 
            and chemical factories.
          </p>
          <p className="content-title"><span>p</span>rojects</p>
          <div className="content-article">
            <p>• Real-time production polymerization monitoring</p>
            <p>• Smart factory 2.0 total solution</p>
          </div>
          <p className="content-title"><span>m</span>aintanance</p>
          <div className="content-article">
            <p>• Sugar, ion exchange resin manufacturing factory, Ulsan.</p>
            <p>• Organic material manufacturing for information electronices factory, Sejong.</p>
          </div>
          <p className="content-title"><span>e</span>xperience</p>
          <div className="content-article content-final">
            <p>• MES(Manufacturing execution systems) process</p>
            <p>• Real-time monitoring</p>
            <p>• Query optimization</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Syds;
