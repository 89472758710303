import React from 'react';

import history from './History';

class ProjectList extends React.Component {
  constructor(props) {
    super(props);

    this.fn_loadProjects = this.fn_loadProjects.bind(this);
  }

  fn_loadProjects = (e, projectName) => {
    switch(projectName) {
      case "dr-diary":
        history.push('/projects/dr-diary');
        this.props.fn_setAboutContent("dr-diary");
        this.props.fn_setMenu("close");
        break;
      case "awool-planet":
        history.push('/projects/awool-planet');
        this.props.fn_setAboutContent("awool-planet");
        this.props.fn_setMenu("close");
        break;
      case "mmume":
        history.push('/projects/mmume');
        this.props.fn_setAboutContent("mmume");
        this.props.fn_setMenu("close");
        break;
      case "syds":
        history.push('/projects/syds');
        this.props.fn_setAboutContent("syds");
        this.props.fn_setMenu("close");
        break;
      case "ariel-networks":
        history.push('/projects/ariel-networks');
        this.props.fn_setAboutContent("ariel-networks");
        this.props.fn_setMenu("close");
        break;
      case "taxi-join":
        history.push('/projects/taxi-join');
        this.props.fn_setAboutContent("taxi-join");
        this.props.fn_setMenu("close");
        break;
      default:
        history.push('/');
        break;
    }
  }

  render() {
    return (
      <div className="work">
        <div className="border"></div>
        <div className={"row work-list " + (this.props.aboutContent === "dr-diary" ? "u-color-drdiary" : "") + (this.props.aboutContent !== 'dr-diary' && this.props.menu === 'close' ? 'menu-close' : '')}>
          <div className={"dr-diary " + (this.props.aboutContent !== 'dr-diary' && this.props.menu === 'close' ? 'u-display-none-xs' : '')}><div className="dr-diary__hover" onClick={(event) => {this.fn_loadProjects(event, 'dr-diary')}}>&nbsp;</div></div>
          <div className="work-list__date">
            <div>Dec. 2019</div>
            <div>Present</div>
          </div>
          <div className="work-list__title">
            DR. DIARY
          </div>
        </div>
        <div className={"border " + (this.props.aboutContent !== 'dr-diary' && this.props.menu === 'close' ? 'border-close' : '')}></div>

        <div className={"row work-list " + (this.props.aboutContent === "awool-planet" ? "u-color-awool" : "") + (this.props.aboutContent !== 'awool-planet' && this.props.menu === 'close' ? 'menu-close' : '')}>
          <div className={"awool-planet " + (this.props.aboutContent !== 'awool-planet' && this.props.menu === 'close' ? 'u-display-none-xs' : '')}><div className="awool-planet__hover" onClick={(event) => {this.fn_loadProjects(event, 'awool-planet')}}>&nbsp;</div></div>
          <div className="work-list__date">
            <div>Jan. 2018</div>
            <div>Dec. 2019</div>
          </div>
          <div className="work-list__title">
            AWOOL PLANET
          </div>
        </div>
        <div className={"border " + (this.props.aboutContent !== 'awool-planet' && this.props.menu === 'close' ? 'border-close' : '')}></div>
        
        <div className={"row work-list " + (this.props.aboutContent === "mmume" ? "u-color-mmume" : "") + (this.props.aboutContent !== 'mmume' && this.props.menu === 'close' ? 'menu-close' : '')} >
          <div className={"mmume " + (this.props.aboutContent !== 'mmume' && this.props.menu === 'close' ? 'u-display-none-xs' : '')}><div className="mmume__hover" onClick={(event) => {this.fn_loadProjects(event, 'mmume')}}>&nbsp;</div></div>
          <div className="work-list__date">
            <div>Feb. 2017</div>
            <div>Oct. 2017</div>
          </div>
          <div className="work-list__title">
            MMUME
          </div>
        </div>
        <div className={"border " + (this.props.aboutContent !== 'mmume' && this.props.menu === 'close' ? 'border-close' : '')}></div>

        <div className={"row work-list "  + (this.props.aboutContent === "syds" ? "u-color-syds" : "") + (this.props.aboutContent !== 'syds' && this.props.menu === 'close' ? 'menu-close' : '')}>
        <div className={"syds " + (this.props.aboutContent !== 'syds' && this.props.menu === 'close' ? 'u-display-none-xs' : '')}><div className="syds__hover" onClick={(event) => {this.fn_loadProjects(event, 'syds')}}>&nbsp;</div></div>
          <div className="work-list__date">
            <div>Oct. 2016</div>
            <div>Dec. 2017</div>
          </div>
          <div className="work-list__title">
            SYDS
          </div>
        </div>
        <div className={"border " + (this.props.aboutContent !== 'syds' && this.props.menu === 'close' ? 'border-close' : '')}></div>

        <div className={"row work-list " + (this.props.aboutContent === "ariel-networks" ? "u-color-ariel" : "") + (this.props.aboutContent !== 'ariel-networks' && this.props.menu === 'close' ? 'menu-close' : '')}>
        <div className={"ariel " + (this.props.aboutContent !== 'ariel-networks' && this.props.menu === 'close' ? 'u-display-none-xs' : '')}><div className="ariel__hover" onClick={(event) => {this.fn_loadProjects(event, 'ariel-networks')}}>&nbsp;</div></div>
          <div className="work-list__date">
            <div>Jun. 2016</div>
            <div>Aug. 2016</div>
          </div>
          <div className="work-list__title">
            ARIEL NETWORKS
          </div>
        </div>
        <div className={"border " + (this.props.aboutContent !== 'ariel-networks' && this.props.menu === 'close' ? 'border-close' : '')}></div>

        <div className={"row work-list " + (this.props.aboutContent === "taxi-join" ? "u-color-taxi" : "") + (this.props.aboutContent !== 'taxi-join' && this.props.menu === 'close' ? 'menu-close' : '')}>
        <div className={"taxi-join " + (this.props.aboutContent !== 'taxi-join' && this.props.menu === 'close' ? 'u-display-none-xs' : '')}><div className="taxi-join__hover" onClick={(event) => {this.fn_loadProjects(event, 'taxi-join')}}>&nbsp;</div></div>
          <div className="work-list__date">
            <div>Apr. 2014</div>
            <div>Apr. 2016</div>
          </div>
          <div className="work-list__title">
            TAXI JOIN
          </div>
        </div>
        <div className={"border " + (this.props.aboutContent !== 'taxi-join' && this.props.menu === 'close' ? 'border-close' : '')}></div>

      </div>
    );
  }
}

export default ProjectList;
