import React from 'react';

class AboutMe extends React.Component {
  render() {
    return (
    <div className="about-me">
      <p className="content-title"><span>i</span>ntroduction</p>
      <div className="content-article">
        {/* <p>Status: open position for remote job. </p> */}
        <p>
          Hello! My name is Kyungmin Kim residing in South Korea. I am a web developer at Awool Planet. 
          I am currently into CLOUD skills, especially on AWS, NodeJS design pattern, and automation. 
          I am a perfectionist, who often pursues reliable and flexible code. I also love playing basketball and drinking coffee! :)
        </p>
      </div>
      <div className="row about-me-img-box">
        <img className="about-me-img" src={require('./../images/about-me-etc03.jpeg')} alt="Kyungmin Kim" />
      </div>
      <p className="content-title"><span>e</span>ducation</p>
      <div className="content-article">
        <p>• (2007) Han Sung High School</p>
        <p>• (2015) Undergraduate, Computer Information Science, Korea University (GPA: 4.0)</p>
      </div>
      <p className="content-title"><span>s</span>kill set</p>
      <div className="content-article">
        <p>• Infra</p>
        <div className="about-me-skill-list">
          <p className="u-level-05">AWS</p>
          <p className="u-level-01">IIS</p>
          <p className="u-level-04">NGINX</p>
        </div>
        <p>• Back-end & DB</p>
        <div className="about-me-skill-list">
          <p className="u-level-05">NODE JS</p>
          <p className="u-level-04">Express</p>
          <p className="u-level-03">ASP.NET</p>
          <p className="u-level-05">MongoDB</p>
          <p className="u-level-04">MYSQL</p>
          <p className="u-level-03">MSSQL</p>
          <p className="u-level-03">Firebase</p>
        </div>
        <p>• Front-end</p>
        <div className="about-me-skill-list">
          <p className="u-level-04">React</p>
          <p className="u-level-04">React-Native</p>
          <p className="u-level-02">Angular 2</p>
          <p className="u-level-02">IONIC 2</p>
          <p className="u-level-01">Cordova</p>
          <p className="u-level-05">Jquery</p>
          <p className="u-level-04">CSS</p>
          <p className="u-level-04">SASS</p>
          <p className="u-level-02">LESS</p>
        </div>
      </div>
      <p className="content-title"><span>r</span>eason why you should NOT hire me</p>
      <div className="content-article">
        <p>• Limited experience: Task management tool such as Webpack and Grunt etc., in toy project, <span className="u-color-red">only</span></p>
        <p>• I Love to use AWS services, but not having experience to handle massive logs and setting delicate log systems, <span className="u-color-red">yet</span></p>
        <p>• Having knowledge of Docker, but no experience of using Kubernetes, <span className="u-color-red">yet</span></p>
        <p>• Not having experience to use Kafka, <span className="u-color-red">yet</span></p>
      </div>
      <p className="content-title"><span>h</span>owever,</p>
      <div className="content-article">
        <p>• I am eager to learn things what solve problems and consistently say coding is my passion.</p>
      </div>
      <p className="content-title"><span>c</span>ontact</p>
      <div className="content-article content-final">
        <p>primetrm@gmail.com</p>
      </div>
    </div>
    );
  }
}

export default AboutMe;
