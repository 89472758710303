import React from 'react';
import Swiper from 'react-id-swiper';

class Mmume extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initialState;
  }

  get initialState() {
    return {
      isLoading: true
    };
  }

  componentDidMount() {
    this.setState({isLoading: false});
  }

  render() {
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      spaceBetween: 10,
      slidesPerView: 4.2,
      freeMode: true,
    }
    
    return (
      this.state.isLoading ? <div>Loading...</div> :
      <div className="">
        <div className="mmume__logo">
          <p className="mmume-title"><span>a</span>bstract</p>
          <div className="mmume-article">
            <p>
              ‘Mmume’ is the name of a virtual dust pixie that lives within a 
              smart IoT flower pot. Mmume has its own storyline; Mmume has survived 
              through air pollution and has traveled a long distance to settle 
              in the flower pot, which Mmume becomes friends with it and its plant. 
            </p>
            <p>
              This little dust helps humans to communicate with the plant. 
              Mmume’s flower pot is for people who have difficulty taking good care 
              of their plants for a long period of time. It is designed with 
              a hydroponic cultivation method to grow plants easily.
            </p>
          </div>
          <div className="row mmume-img-box">
            <div className="col-lg-12 col-xl-6 mmume-img"></div>
            <div className="col-lg-12 col-xl-6 mmume-img-2nd"></div>
          </div>
          <p className="mmume-article">
            This innovative way of raising plants is fun and exciting because 
            the application constantly interacts with its users. Mmume gives 
            users a daily quests and notification, like a mobile game, in which 
            users can develop Mmume character. As Mmume grows, users earn points 
            to buy off-line event tickets, such as flower arrangement classes. 
            Also, Mmume represents plants’ various feelings and needs. The color 
            of the flower pot as well as Mmume simultaneously change based on 
            its plant's real-time data, such as temperature, illumination and water 
            level, compared to optimized conditions. The purpose of Mmume is that 
            users sympathize with their plants more easily and deeply.
          </p>
        </div>
        <div>
          <p className="mmume-title"><span>p</span>roject</p>
          <div className="mmume__swiper mmume-article">
            <Swiper className="swiper" {...params}>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/mmume_01.png')} alt={'Mmume Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/mmume_02.png')} alt={'Mmume Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/mmume_03.png')} alt={'Mmume Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/mmume_04.png')} alt={'Mmume Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/mmume_05.png')} alt={'Mmume Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/mmume_06.png')} alt={'Mmume Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/mmume_07.png')} alt={'Mmume Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/mmume_08.png')} alt={'Mmume Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/mmume_09.png')} alt={'Mmume Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/mmume_10.png')} alt={'Mmume Project'} /></div>
              </div>
              <div className="slide">
                <div><img className="slide-img" src={require('./../images/mmume_11.png')} alt={'Mmume Project'} /></div>
              </div>
            </Swiper>
          </div>
        </div>
        <div className="mmume-final-content">
          <p className="mmume-title"><span>a</span>chivement</p>
          <div className="row mmume-article">
            <div className="col-12">• SAMSUNG Creative Square</div>
            <div className="col-12">• 2017 Berlin IFA</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Mmume;
