import React from 'react';
import Swiper from 'react-id-swiper';
// import Swiper from 'react-id-swiper';

class AwoolPlanet extends React.Component {
  render() {
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      spaceBetween: 10,
      slidesPerView: 4.2,
      freeMode: true,
    }
    return (
      <div className="content awool">
        <p className="content-title"><span>a</span>bstract</p>
        <div className="content-article">
          <p>Dr. Diary is helping diabetes users from record of blood sugar level to diet and insurance. Dr. Diary is heading up to total solutions platform of diabetes.</p>
          <p>Dr. Diary is chosen by 30 Under 30 from Forbes Asia in 2020. and The number of app downloads: 310,000, Accumulated data: 9,000,000</p>
          <p>Our slogan is "Taking care of diabetes conveniently, We prepare everything about diabetes for you."</p>
        </div>
        <div className="content-article">
          <Swiper className="swiper" {...params}>
            <div className="slide">
              <div><img className="slide-img" src={require('./../images/drdiary_01.png')} alt={'Dr. Diary Screen'} /></div>
            </div>
            <div className="slide">
              <div><img className="slide-img" src={require('./../images/drdiary_02.png')} alt={'Dr. Diary Screen'} /></div>
            </div>
            <div className="slide">
              <div><img className="slide-img" src={require('./../images/drdiary_03.png')} alt={'Dr. Diary Screen'} /></div>
            </div>
            <div className="slide">
              <div><img className="slide-img" src={require('./../images/drdiary_04.png')} alt={'Dr. Diary Screen'} /></div>
            </div>
            <div className="slide">
              <div><img className="slide-img" src={require('./../images/drdiary_05.png')} alt={'Dr. Diary Screen'} /></div>
            </div>
          </Swiper>
        </div>
        <p className="content-title"><span>E</span>xperience</p>
        <p className="content-article">
          I work as Back-end Programmer and CTO. I put a lot of effort to learn great qualities of leadership and philosophy from books like 
          "Leadership and self-deception: getting out of the box", "The outward mindset" and "Nonviolent Communication Shortly, NVC". Those books have inspired me what great leadership's basic concept is.
          Dev team built a great culture and satifactory relationships beyond efficient development process.
        </p>
        <p className="content-title"><span>p</span>rojects</p>
        <div className="content-article">
          <p>• Dr. Diary Blood Glucose Meter Subscription System Development</p>
          <p>• Dr. Diary Mall E-commerce Maintanance </p>
          <p>• Dr. Diary App v2 Server Side Development </p>
          <p>• Dr. Diary Admin System Maintanance </p>
        </div>
        <p className="content-title"><span>a</span>chivement</p>
        <div className="content-article content-final">
          <p>• Learning great qualities of leadership </p>
          <p>• <img className="drdiary-img-icon" src={require('./../images/drdiary_checked.png')} alt={'AWS Certification Mark'} /> AWS Certificated Solution Architecture - Associate</p>
          <p>• Handling with massive traffic</p>
          <p>• Implemented reliable and scalable server architecture</p>
          <p>• Optimized MongoDB with Performance Advisor & Profiler, etc.</p>
        </div>
      </div>
      
    );
  }
}

export default AwoolPlanet;
