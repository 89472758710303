import React from 'react';
import Swiper from 'react-id-swiper';

class AwoolPlanet extends React.Component {
  render() {
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      spaceBetween: 10,
      slidesPerView: 4.2,
      freeMode: true,
    }
    return (
      <div className="content awool">
        <p className="content-title"><span>a</span>bstract</p>
        <div className="content-article">
          <p>Platform : Real Korean wave contents for Chinese</p>
          <p>
            Awool Planet gives Chinese customers real Korean wave contents fast 
            and easy. Mainland Chinese people cannot use popular SNS, such as youtube, 
            twitter, and facebook. They use their own SNS platform in China. However, 
            when they want to search for specific information on K-pop, they use on 
            average of four different apps. Even so, the information they find is not 
            genuine, since it could be wrong made by Chinese. Furthermore, it is very 
            difficult for many Chinese to buy K-pop related goods or concert tickets.
          </p>
        </div>
        <div className="row awool-img-box">
          {/* <div className="col-lg-12 col-xl-6 awool-img"></div> */}
          {/* <div className="col-lg-12 col-xl-6 awool-img-2nd"></div> */}
          <video width="100%" controls>
            <source src="/videos/awoolplanet_intro.mp4" type="video/mp4" />
            <source src="movie.ogg" type="video/ogg" />
              Your browser does not support the video tag.
          </video>
        </div>
        <p className="content-article">
          Awool Planet wants to solve these problems and focus on user’s convenience. 
          Using Awool Planet’s website, users can search their favorite celebrities more 
          efficiently and accurately. Also, they can buy products and tickets at a cheaper 
          cost. Awool Planet system gives great user experience. Users do not have to wait 
          for their tickets to arrive home. The system publishes effective e-tickets to 
          users immediately after the purchase. In addition, this system conveniently connects 
          users and merchants. Merchants do not need any devices, such as a barcode reader. 
          All users need is a mobile device, which everyone already has at least one. 
          The system uses QR code that can be scanned by the merchant and vice versa.
        </p>
        <div className="content-article">
          <Swiper className="swiper" {...params}>
            <div className="slide">
              <div><img className="slide-img" src={require('./../images/awoolplanet_htu01.gif')} alt={'Awool Planet Project'} /></div>
            </div>
            <div className="slide">
              <div><img className="slide-img" src={require('./../images/awoolplanet_htu02.gif')} alt={'Awool Planet Project'} /></div>
            </div>
            <div className="slide">
              <div><img className="slide-img" src={require('./../images/awoolplanet_htu03.gif')} alt={'Awool Planet Project'} /></div>
            </div>
            <div className="slide">
              <div><img className="slide-img" src={require('./../images/awoolplanet_htu04.gif')} alt={'Awool Planet Project'} /></div>
            </div>
            <div className="slide">
              <div><img className="slide-img" src={require('./../images/awoolplanet_htu05.gif')} alt={'Awool Planet Project'} /></div>
            </div>
            <div className="slide">
              <div><img className="slide-img" src={require('./../images/awoolplanet_htu06.gif')} alt={'Awool Planet Project'} /></div>
            </div>
          </Swiper>
        </div>
        <p className="content-title"><span>p</span>rojects</p>
        <div className="content-article">
          <p>• Yemao Planet Beta Version</p>
          <p>• YemaoXingqiu System v1.0 in South Korea</p>
          <p>• Yemao Planet Company Website</p>
          <p>• YemaoXingqiu System v2.0 in China & South Korea</p>
          <p>• YemaoXingqiu Admin System</p>
        </div>
        <p className="content-title"><span>a</span>chivement</p>
        <div className="content-article content-final">
          <p>• Scalable Server Architecture</p>
          <p>• Built & Run Service In China and South Korea</p>
        </div>
      </div>
      
    );
  }
}

export default AwoolPlanet;
